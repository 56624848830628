import React, { useState, useRef, useEffect } from "react";
import L from "leaflet";
import processedShapefiles from "../shapefiles/processedShapefiles.json";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import graph1 from "../static/img/graph1.JPG";
import graph2 from "../static/img/graph2.JPG";
import { DownloadFile, HTMLtoPNG } from "./DownloadFile";
import MapTiff from "./MapTiff.js";
import Graph from "./Graph.js";
import "../static/css/date.css";
import { dateToString, pathGenerator } from "./Utils.js";
import HomeInteractive from "./HomeInteractive.js";
import { getTiff, getLastMapUpdateDate } from "../FetchReq.js";
import myCache, { cacheConfig } from "../Cache.js";
let getMapUpdateDate = async () => {
  const date_from_server = await getLastMapUpdateDate().then((res) => {
    // console.log("res", res);
    let date = res["lastMapUpdateDate"];
    // console.log("date", date);
    return date;
  });

  let dateObj = new Date(date_from_server);
  // console.log("date_from_server", date_from_server);
  return dateObj;
};

const InteractiveMap = () => {
  const legendsData = myCache.get("legendsData")["legendsInfo"];
  let [DateObj, setDateObj] = useState(null);
  let [lastMapUpdateDate, setLastMapUpdateDate] = useState("20210504");
  let [lastMapUpdateDate_, setLastMapUpdateDate_] = useState("");

  // Date Picker
  const [datePicker, setDatePicker] = useState(lastMapUpdateDate_);

  // Area of Interest - Handle Variable
  const [areaOfInterest, setAreaOfInterest] = useState("Country Level");

  // Select Legends
  const [legendIndex, setLegendIndex] = useState(1);

  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState("1 Year");
  const [value4, setValue4] = useState(0);

  // Type - Nowcast or Forecast
  const [predictionType, setPredictionType] = useState("nowcast");

  const [minDate, setMinDate] = useState("1981-01-01");
  const [maxDate, setMaxDate] = useState(lastMapUpdateDate_);

  const [date, setDate] = useState(lastMapUpdateDate);
 
  // ref to map on this page
  const map = React.createRef();
  
  useEffect(() => {
    getMapUpdateDate().then((res) => {
      setDateObj(res);
      setLastMapUpdateDate(dateToString(res));
      setLastMapUpdateDate_(dateToString(res, true));
      setDatePicker(dateToString(res, true));
      let newDate = dateToString(res);
      tiffUrl = pathGenerator({
        index: legendIndex,
        prediction: predictionType,
        date: newDate,
      });
      if(map.current!=null)
        map.current.setTiffUrl(tiffUrl);
      setDate(newDate);
    });
  }, []);

  // date variable for calender and filenames 
  var tiffUrl = pathGenerator({
    index: legendIndex,
    prediction: predictionType,
    date: date,
  });

  // Handle Change Function - Area of Interest
  const changeAreaOfInterest = (e) => {
    let newAreaOfInterest = e.target.value;
    setAreaOfInterest(newAreaOfInterest);

    if (newAreaOfInterest === "Country Level") {
      map.current.setFeatureLevel(0);
    } else if (newAreaOfInterest === "State Level") {
      map.current.setFeatureLevel(1);
    } else if (newAreaOfInterest === "District Level") {
      map.current.setFeatureLevel(2);
    } else if (newAreaOfInterest === "Administrative Level") {
      map.current.setFeatureLevel(3);
    } else if (newAreaOfInterest === "Clear Area") {
      map.current.setFeatureLevel(-1);
    }
  };
  

  // Handle Change Function - Variable Name
  const changeLegendIndex = (e) => {
    setLegendIndex(e.target.value);
    map.current.setLegendIndex(e.target.value);
    tiffUrl = pathGenerator({
      index: e.target.value,
      prediction: predictionType,
      date: date,
    });
    map.current.setTiffUrl(tiffUrl);
  };

  const handleChange2 = (e) => {
    setValue2(e.target.value);
  };
  const handleChange3 = (e) => {
    setValue3(e.target.value);
  };
  const handleChange4 = (e) => {
    setValue4(e.target.value);
  };

  const changeType = (e) => {
    setPredictionType(e.target.value);
    if (e.target.value === "forecast") {
      const newForecastDateObj = new Date();
      newForecastDateObj.setDate(
        newForecastDateObj.getDate() -
          ((newForecastDateObj.getDay() + 4) % 7) +
          1
      );

      let newForecastDate_ = dateToString(newForecastDateObj, true);
      let newForecastDate = dateToString(newForecastDateObj, false);

      setDate(newForecastDate);
      setDatePicker(newForecastDate_);
      setMinDate(newForecastDate_);

      newForecastDateObj.setMonth(newForecastDateObj.getMonth() + 4);

      let maxForecastDate_ = dateToString(newForecastDateObj, true);
      setMaxDate(maxForecastDate_);
      tiffUrl = pathGenerator({
        index: legendIndex,
        prediction: e.target.value,
        date: newForecastDate,
      });
    } else if (e.target.value === "nowcast") {
      setMinDate("1981-01-01");
      setMaxDate(lastMapUpdateDate_);
      setDatePicker(lastMapUpdateDate_);
      setDate(lastMapUpdateDate);
      tiffUrl = pathGenerator({
        index: legendIndex,
        prediction: e.target.value,
        date: lastMapUpdateDate,
      });
    }
    map.current.setTiffUrl(tiffUrl);
  };

  // Handle Change Function - DatePick from Calender
  const onCalenderDateChange = () => {
    let newDate = document.getElementById("datePick").value;
    setDatePicker(newDate);
    newDate = newDate.replace(/-| /g, "");
    tiffUrl = pathGenerator({
      index: legendIndex,
      prediction: predictionType,
      date: newDate,
    });
    map.current.setTiffUrl(tiffUrl);
    setDate(newDate);
  };

  const refer = React.createRef();

  const [mapUpdate, didMapUpdate] = useState({
    featureId: 0,
  });

  return (
    <>
      <HomeInteractive />
      <div className="flex justify-center font-ArialNormal z-10 mx-5">
        <div className="mt-4 flex lg:flex-row flex-col text-4xl">
          <div ref={refer} className="right">
            {/* Display map from map component */}
            <div className="mt-4">
              <div className="border-4 grid md:grid-flow-row xl:grid-flow-col items-center justify-center mx-auto lg:space-x-5 space-x-0 px-5 bg-[#f8f9fa]">
                <div className="mx-auto justify-center text-center w-full py-2 -mt-1 text-lg item-center">
                  <p className="mx-auto font-bold">Date</p>
                  <div className="text-center my-2 mx-auto ">
                    <TextField
                      type="date"
                      InputProps={{ style: { fontWeight: " bold" } }}
                      InputLabelProps={{ style: { fontWeight: " bold" } }}
                      inputProps={{ min: minDate, max: maxDate }}
                      value={datePicker}
                      onChange={onCalenderDateChange}
                      id="datePick"
                      // disable keyboard input //
                      // onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="mx-auto justify-center text-center py-2 text-lg item-center">
                  <p className="mx-auto font-bold">Area of Interest </p>
                  <select
                    className="text-center my-2 mx-auto border-solid border-2 font-Arialbold  text-xs border-orange-300 rounded-full hover:border-blue-300 w-44 h-10"
                    value={areaOfInterest}
                    onChange={changeAreaOfInterest}
                  >
                    <option value="Country Level">Country Level</option>
                    <option value="State Level">State Level</option>
                    <option value="District Level">District Level</option>
                    <option value="Administrative Level">
                      Administrative Level
                    </option>
                    <option value="Clear Area">Clear Area</option>
                  </select>
                </div>
                <div className="mx-auto justify-center text-center py-2 text-lg ">
                  <p className="font-bold">Prediction </p>
                  <select
                    className="text-center my-2 border-solid border-2 font-Arialbold text-xs border-orange-300 rounded-full hover:border-blue-300 w-28 h-10"
                    value={predictionType}
                    onChange={changeType}
                  >
                    <option value="nowcast">Nowcast</option>
                    <option value="forecast">Forecast</option>
                  </select>
                </div>
                <div className="mx-auto justify-center text-center p-2 text-lg ">
                  <p className="font-bold capitalize">
                    Select Indices/Variables{" "}
                    <span>
                      {" "}
                      <FontAwesomeIcon
                        title={decodeURI("MB: Model Based")}
                        className="h-4 w-4"
                        icon={faInfoCircle}
                      />{" "}
                    </span>{" "}
                  </p>
                  <select
                    className="text-center px-2 my-2 border-solid border-2 font-Arialbold text-xs border-orange-300 rounded-full hover:border-blue-300 w-60 h-10"
                    value={legendIndex}
                    onChange={changeLegendIndex}
                  >
                    {legendsData.map((legend, index) =>
                      legend.activeStatus == "0" ? (
                        <option key={index} value={index} disabled>
                          {legend.variableName}
                        </option>
                      ) : (
                        <option key={index} value={index}>
                          {legend.variableName}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>

              <div className="">
                {
                  <MapTiff
                    legendsData={legendsData}
                    predictionType={predictionType}
                    position={[14.716, -13.467]}
                    enableLegends={true}
                    featureLevel={0}
                    // disableLevelButton={true}
                    legendIndex={1}
                    ref={map}
                    data={processedShapefiles}
                    tiffUrl={tiffUrl}
                    fromWhere={"interactive"}
                    onMapUpdate={didMapUpdate}
                  />
                }
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <div className="">
                <div className="mb-2 text-2xl">Download</div>
                {
                  <DownloadFile
                    tiffUrl={tiffUrl}
                    fileName={legendsData[legendIndex].variableCode}
                    prediction={predictionType}
                    innerRef={map}
                    date={date}
                  />
                }
              </div>
              <div className="my-14">
              </div>
            </div>
          </div>
          <hr />
          <div className="left mx-5 mt-4 p-1 justify-between text-center">
            <div className="h-full">
              <div className="h-20 flex justify-center items-center space-x-4">
                <div className="py-5 text-lg ">
                  <p className="mx-10 font-bold py-2">Select Periodicity</p>
                  <select
                    className="text-center border-solid border-2 font-Arialbold text-xs border-orange-300 rounded-full hover:border-blue-300 w-56 h-10"
                    value={value3}
                    onChange={handleChange3}
                  >
                    <option value="1 Month">1 Month</option>
                    <option value="3 Months">3 Months</option>
                    <option value="1 Year">1 Year</option>
                  </select>
                </div>
              </div>
              <p className="text-xs py-4">
                An area chart compares Min and Max values of nowcast and
                forecast data of MS:{" "}
                <span className="font-bold">{value2} </span> in Senegal region |{" "}
                <span className="font-bold">Periodicity: {value3}</span>
              </p>
              <span className="text-gray-500 text-base">Time Series Plot</span>
              <div className="border border-1-solid md:mb-20">
                {
                  <Graph
                    periodicity={value3}
                    index={legendIndex}
                    selectedDate={date}
                    prediction={predictionType}
                    innerRef={map}
                    didMapUpdate={didMapUpdate}
                  />
                }
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default InteractiveMap;
