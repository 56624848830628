import React from 'react'

const Reports = () => {
  //This page is for future use
  return (
      <div className="text-center flex min-h-screen my-auto">
        <h1 className='text-xl md:text-4xl my-auto mx-auto'>Available soon</h1>
      </div>
  )
}

export default Reports