/*
## File Description ##
- This functional component is used for downloading the GeoTiff file.
- Incomplete functionality
*/

import fileDownload from "js-file-download";
import { displayError, path } from "./Utils.js";
import myCache from "../Cache.js";
import React from "react";
import parseGeoraster from "georaster";
import html2canvas from "html2canvas";

function DownloadFile(props) {
  // Assign tiff image to tiffUrl variable
  let tiffUrl = props.tiffUrl;
  let reff = props.innerRef;
  // This function will be called when user clicks on the download button //
  // It fetches the tiff file from cache and downloads it to the local machine //
  // Tiff File: Clipped according to the user's selection //
  const handleClick = async (url, filename, format) => {
    // Get the tiff file from cache //
    let CacheKey = url.split("/")[2] + "_" + reff.current.state.featureId;
    let featureID = reff.current.state.featureId;
    let tiffFile_b64_string = myCache.get(CacheKey);
    if (tiffFile_b64_string == undefined) {
      displayError("Data not available");
      return;
    }

    let tiffFile_arrayBuffer = Uint8Array.from(atob(tiffFile_b64_string), (c) =>
      c.charCodeAt(0)
    ).buffer;

    // download the tiff file //
    if (format == "tif") {
      var arrayBufferView = new Uint8Array(tiffFile_arrayBuffer);
      let element = document.createElement("a");
      let file = new Blob([arrayBufferView], { type: "image/tiff" });
      element.href = URL.createObjectURL(file);
      element.download = filename + "_" + featureID + ".tif";
      element.click();

      // delete the link after it is clicked //
      element.remove();
      return;
    } else {
      // Convert canvas to image //
      const w = document.getElementById("map").offsetWidth
      const h = document.getElementById("map").offsetHeight
      const canvas = await html2canvas(document.getElementById("map"), {
        width: w,
        height: h
      });
      // Display the Canvas //
      let div = document.createElement("div");
      div.setAttribute("id", "canvasDiv");
      const header =
        reff.current.legendsData[reff.current.state.legendIndex][
          "variableName"
        ];
      div.innerHTML += "<h1>" + header + "</h1></br>";

      div.appendChild(canvas);
      div.style.display = "flex";
      div.style.flexFlow = "column";
      div.style.textAlign = "center";
      div.style.margin = "auto"
      div.style.alignItems = "center";
      div.style.justifyContent = "center";
      
      div.style.width = (w - 55)+"px";
      div.style.paddingTop = "3%";
      div.style.paddingRight = "5%";
      div.style.height = (h + 50)+"px";

      document.body.appendChild(div);
      html2canvas(div).then((canvas) => {
        const urll = canvas.toDataURL("image/" + format);
        const link = document.createElement("a");
        link.download = filename + "_" + featureID + "." + format;
        link.href = urll;
        link.click();
        // delete the link after it is clicked //
        link.remove();
      });
      document.body.removeChild(div);
    }
    return;
  };

  return (
    <div className="flex space-x-4">
      <div className="cursor-pointer">
        <a
          onClick={() => {
            handleClick(
              tiffUrl,
              props.fileName + "_" + props.date + "_" + props.prediction,
              "tif"
            );
          }}
        >
          {" "}
          <img
            width="50px"
            src="https://img.icons8.com/external-others-iconmarket/45/000000/external-tiff-file-types-others-iconmarket.png"
          />{" "}
        </a>
      </div>

      <div className="cursor-pointer">
        {/* HandleClick call from <a> tag  */}
        <a
          onClick={() => {
            handleClick(
              tiffUrl,
              props.fileName + "_" + props.date + "_" + props.prediction,
              "png"
            );
          }}
        >
          {" "}
          <img
            width="50px"
            src="https://img.icons8.com/external-others-iconmarket/512/external-png-file-types-others-iconmarket.png"
          />{" "}
        </a>
      </div>
    </div>
  );
}

export { DownloadFile };
