/*
## File Description ##
- This component is responsible for rendering a time series graph of the selected feature.
- It uses the react-chartjs-2 library to render the graph.
- It uses the Cache.js file to fetch the data from the cache.
*/

import { Component, useEffect, useState } from "react";
import { pathGenerator, dateToString } from "./Utils";
import { getTSData } from "../FetchReq.js";
import graph1 from "../static/img/graph1.JPG";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { capitalize } from "@material-ui/core";
import { saveAs } from 'file-saver';
import myCache, { cacheConfig } from "../Cache.js";
Chart.register(...registerables);

export default class Graph extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      avgData: [],
      maxData: [],
      minData: [],
      dates: [],
      isDataLoaded: false,
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.periodicity = this.props.periodicity;
    this.selectedDate = this.props.selectedDate;
    this.prediction = this.props.prediction;
    this.index = this.props.index;
    let legend = myCache.get("legendsData")["legendsInfo"][this.index];
    this.variableCode = legend.variableCode;
    this.getGraphData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.periodicity = this.props.periodicity;
      this.selectedDate = this.props.selectedDate;
      this.prediction = this.props.prediction;
      this.index = this.props.index;
      let legend = myCache.get("legendsData")["legendsInfo"][this.index];
      this.variableCode = legend.variableCode;
      this.setState({
        avgData: [],
        maxData: [],
        minData: [],
        dates: [],
        isDataLoaded: false,
      });
      this.getGraphData();
    }
  }
  getGraphData = async () => {
    // log all the variables of this class //
    let data = await getTSData({
      periodicity: this.periodicity,
      selectedDate: this.selectedDate,
      prediction: this.prediction,
      variableCode: this.variableCode,
      featureId: this.props.innerRef.current.state.featureId,
      highestFeatureId: this.props.innerRef.current.state.highestFeatureId,
      featureLevel: this.props.innerRef.current.state.featureLevel,
    }).then((data) => {
      return data;
    });
    // if data has avgPixelValues, stdPixelValues and dates then set the state //
    if (
      data["avgPixelValues"] !== undefined &&
      data["maxPixelValues"] !== undefined &&
      data["minPixelValues"] !== undefined &&
      data["dates"] !== undefined
    ) {
      this.setState({
        avgData: data["avgPixelValues"],
        maxData: data["maxPixelValues"],
        minData: data["minPixelValues"],
        dates: data["dates"],
        isDataLoaded: true,
      });
    } else {
      this.setState({
        avgData: [],
        maxData: [],
        minData: [],
        dates: [],
        isDataLoaded: true,
      });
    }
  };

  saveCanvas() {
    //save to png
    const canvasSave = document.getElementById('myChart');
    canvasSave.toBlob(function (blob) {
        saveAs(blob, "Chart.png")
    })
  }

  exportCSVFile = () => {
    let csv = "Date,Average,Maximum,Minimum"
    csv += "\n"
    for (let i = 0; i < this.state.dates.length; i++) {
      csv += this.state.dates[i] + "," + this.state.avgData[i] + "," + this.state.maxData[i] + "," + this.state.minData[i]
      csv += "\n"
    }
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, this.props.innerRef.current.state.featureId + "_"+ this.prediction + "_" + this.variableCode+ ".csv")
  }

  render() {
    if (
      this.state.isDataLoaded &&
      (this.state.avgData.length == 0 ||
        this.state.maxData.length == 0 ||
        this.state.minData.length == 0 ||
        this.state.dates.length == 0)
    ) {
      // print("No data to render")
      return (
        <div className="my-2 mb-64 mx-auto">
          <div className="w-full">
            <img className="opacity-25 mx-auto object-cover" src={graph1} />
          </div>
          <div className="mx-auto px-[2rem] text-red-600 text-4xl font-bold -mt-64 bg-slate-50 bg-opacity-[0.4]">
            {" "}
            <span className=""> No Data to Render </span>
          </div>
        </div>
      );
    }
    // const avgMinusStd = [];
    // const avgPlusStd = [];
    // let avg = this.state.avgData;
    // let std = this.state.maxData;

    // for (let i = 0; i < avg.length; i++) {
    //   avgMinusStd.push(avg[i] - std[i]);
    //   avgPlusStd.push(avg[i] + std[i]);
    // }

    return (
      <>
        {!this.state.isDataLoaded && (
          <div className="w-10 text-center mx-auto my-5">
            <svg
              fill="#000000"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 26.349 26.35"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <circle cx="13.792" cy="3.082" r="3.082"></circle>{" "}
                    <circle cx="13.792" cy="24.501" r="1.849"></circle>{" "}
                    <circle cx="6.219" cy="6.218" r="2.774"></circle>{" "}
                    <circle cx="21.365" cy="21.363" r="1.541"></circle>{" "}
                    <circle cx="3.082" cy="13.792" r="2.465"></circle>{" "}
                    <circle cx="24.501" cy="13.791" r="1.232"></circle>{" "}
                    <path d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05 C6.902,18.996,5.537,18.988,4.694,19.84z"></path>{" "}
                    <circle cx="21.364" cy="6.218" r="0.924"></circle>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          </div>
        )}

        <div className="h-full min-h-[400px] w-full">
          {this.state.isDataLoaded && (
            <Line
              id="myChart"
              ref={this.chartRef}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                zoomEnabled: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text:
                      "Time Series for " +
                      capitalize(this.variableCode) +
                      " | " +
                      capitalize(this.prediction) +
                      " Prediction",
                    position: "top",
                  },
                },
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: ""+this.variableCode,
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                },
                zoom: {
                  enabled: true,
                  drag: true,
                  mode: "x",
                  speed: 0.1,
                  limits: {
                    max: 10,
                    min: 0.5,
                  },
                },
                pan: {
                  enabled: true,
                  mode: "x",
                  speed: 10,
                  threshold: 10,
                },
              }}
              data={{
                labels: this.state.dates,
                datasets: [
                  {
                    label: "Average",
                    data: this.state.avgData,
                    borderColor: "black",
                    backgroundColor: "black",
                    fill: 0,
                    tension: 0.4,
                    pointRadius: 1,
                  },
                  {
                    label: "Min",
                    data: this.state.minData,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    fill: 1,
                    pointRadius: 0,
                  },
                  {
                    label: "Max",
                    data: this.state.maxData,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    fill: 1,
                    pointRadius: 0,
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="flex justify-end">
        <div className="cursor-pointer m-2 flex">
          {/* HandleClick call from <a> tag  */}
          <a
            onClick={() => {
              this.saveCanvas();
            }}
          >
            {" "}
            <img
              width="50px"
              src="https://img.icons8.com/external-others-iconmarket/512/external-png-file-types-others-iconmarket.png"
            />{" "}
          </a>
          {/* <p className="text-orange-400 text-sm">Png</p> */}
        </div>
        <div className="cursor-pointer m-2 flex">
          {/* HandleClick call from <a> tag  */}
          <a
            onClick={() => {
              this.exportCSVFile();
            }}
          >
            {" "}
            <img
              width="50px"
              src="https://img.icons8.com/external-others-iconmarket/256/external-csv-file-types-others-iconmarket.png"
            />{" "}
          </a>
          {/* <p className="text-orange-400 text-sm">Png</p> */}
        </div>
        </div>
      </>
    );
  }
}
