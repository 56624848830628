import React, { Component, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeotiffLayer from "./GeoTiffLayer.js";
import LevelButton from "./LevelButton.js";
import Legends from "./Legends.js";
import { DownloadFile, HTMLtoPNG } from "./DownloadFile";
import { getClippedTiff } from "./Utils";
import { getTiff, getTiff_HTTPReq } from "../FetchReq.js";
const fetch_config = require("../config/fetch.config.json");

// This is custom Map Class which integrates shapefiles and tiff Files on a Map.
export default class MapTiff extends Component {
  constructor(props) {
    super(props);
    this.predictionType = props.predictionType;
    this.enableLegends = props.enableLegends ? props.enableLegends : false;
    this.disableLevelButton = props.disableLevelButton
      ? props.disableLevelButton
      : false;
    this.refOtherGraphs = props.refOtherGraphs ? props.refOtherGraphs : [];
    this.handleEachFeature = this.handleEachFeature.bind(this);
    this.customHandleEachFeature = props.customHandleEachFeature
      ? props.customHandleEachFeature
      : this.handleEachFeature;
    this.referenceData = props.data;
    this.legendsData = props.legendsData;
    this.zoomControl = props.zoomControl ? props.zoomControl : true;
    this.state = {
      legendIndex: props.legendIndex,
      data: props.data,
      map: React.createRef(),
      geoJsonRef: React.createRef(),
      featureId: React.createRef(),
      featureBounds: [],
      prevFeatureId: [],
      tiffUrl: props.tiffUrl,
      position: props.position ? props.position : [14.416, -13.467],
      featureLevel: props.featureLevel == 0 ? props.featureLevel : 1,
      fL: 0,
      fromWhere: props.fromWhere,
      onMapUpdate: props.onMapUpdate ? props.onMapUpdate : () => {},
      zoom: 7,
    };
    this.initializeData(props.data, this.state.featureLevel);
  }

  initializeData(data, level) {
    try {
      this.referenceData = data;
      const tempData = data.features.filter((feature) => {
        if (
          feature.level === level &&
          this.state.featureId !== feature.parent
        ) {
          this.state.prevFeatureId.push(feature.parent);
        }
        return feature.level === level;
      });
      this.state.data = tempData;
      this.state.featureId = data.features[0].current;
      this.state.highestFeatureId = data.features[0].current;
    } catch (err) {}
  }

  setTiffUrl(tiffUrl) {
    // alert("Fetching Tiff")
    this.state.tiffUrl = tiffUrl;
    if (fetch_config["USE_WEBSOCKET"])
      getTiff(tiffUrl, this.state.highestFeatureId);
    else
      getTiff_HTTPReq(
        tiffUrl,
        this.state.highestFeatureId,
        this.state.featureId,
        this.state.fL
      );
  }

  setLegendIndex(legendIndex) {
    this.state.legendIndex = legendIndex;
  }

  setData(data) {
    this.state.data = data;
    this.state.geoJsonRef.current.clearLayers();
    this.state.geoJsonRef.current.addData(this.state.data);
  }

  getHighestFeatureId() {
    return this.state.highestFeatureId;
  }

  clearData() {
    this.state.geoJsonRef.current.clearLayers();
  }

  setFeatureLevel(level) {
    this.state.featureLevel = level;
    this.initializeData(this.referenceData, level);
    this.state.geoJsonRef.current.clearLayers();
    this.state.geoJsonRef.current.addData(this.state.data);
  }

  color = (val) => {
    let index = this.state.legendIndex;
    let legend = this.legendsData[index];

    let n = legend["legend_colors"].length;
    if (isNaN(val[0])) return "transparent";
    for (let i = n - 1; i >= 0; i--) {
      var upper_limit = legend["legend_ranges"][i][1];
      var lower_limit = legend["legend_ranges"][i][0];
      var color = legend["legend_colors"][i];
      if (
        (lower_limit == "-INF" || lower_limit <= val[0]) &&
        (upper_limit == "INF" || val[0] < upper_limit)
      ) {
        return color;
      }
    }
    return "transparent";
  };

  options = {
    pixelValuesToColorFn: (values) => this.color(values),
    resolution: 64,
    opacity: 1.0,
  };

  handleEachFeature(feature, layer) {
    if (!this.disableLevelButton) {
      layer.bindPopup(feature.name);

      layer.on("mouseover", function (e) {
        layer.openPopup();
        layer.setStyle({
          fillColor: "blue",
        });
      });

      layer.on("mouseout", function (e) {
        layer.closePopup();
        layer.setStyle({
          fillColor: "transparent",
          color: "black",
          fillOpacity: 0.2,
        });
      });
      layer.on({
        click: (e) => {
          if (
            !fetch_config["USE_WEBSOCKET"] &&
            this.state.tiffUrl !== undefined
          ) {
            let level = this.state.fL + 1;
            if (this.state.fromWhere === "interactive")
              level = this.state.featureLevel;
            getTiff_HTTPReq(
              this.state.tiffUrl,
              this.state.highestFeatureId,
              feature.current,
              level
            );
          }
          this.state.onMapUpdate(feature.current);
          // check fromWhere //
          if (this.state.fromWhere === "interactive") {
            // load only the clicked feature //
            this.state.geoJsonRef.current.clearLayers();
            this.state.geoJsonRef.current.addData(feature);
            this.state.map.current.fitBounds(e.target.getBounds());
            this.state.featureBounds.push(this.state.map.current.getBounds());
            this.state.prevFeatureId.push(this.state.featureId);
            this.state.featureId = feature.current;

            // load the other graphs //
            for (let i = 0; i < this.refOtherGraphs.length; i++) {
              let tempReference = this.refOtherGraphs[i].current.state;
              tempReference.prevFeatureId = this.state.prevFeatureId;
              tempReference.featureId = this.state.featureId;
              tempReference.featureBounds = this.state.featureBounds;
              tempReference.map.current.fitBounds(e.target.getBounds());
              tempReference.featureBounds.push(
                tempReference.map.current.getBounds()
              );
              tempReference.geoJsonRef.current.clearLayers();
              tempReference.geoJsonRef.current.addData(feature);
            }
            for (let i = 0; i < this.refOtherGraphs.length; i++) {
              this.refOtherGraphs[i].current.forceUpdate();
            }
            this.forceUpdate();
            // Force update the Graph Component //
          } else {
            if (feature.children.length === 0) {
              // Load only the clicked feature //
              this.state.geoJsonRef.current.clearLayers();
              this.state.geoJsonRef.current.addData(feature);
              this.state.map.current.fitBounds(e.target.getBounds());
              this.state.featureBounds.push(this.state.map.current.getBounds());
              this.state.prevFeatureId.push(this.state.featureId);
              this.state.featureId = feature.current;
            } else {
              this.state.prevFeatureId.push(this.state.featureId);
              this.state.featureId = feature.current;
              this.state.map.current.fitBounds(e.target.getBounds());
              this.state.featureBounds.push(this.state.map.current.getBounds());
              this.state.geoJsonRef.current.clearLayers();
            }

            const tempData = this.referenceData.features.filter((item) => {
              return item.parent === this.state.featureId;
            });
            this.state.data = tempData;
            this.state.geoJsonRef.current.addData(this.state.data);

            for (let i = 0; i < this.refOtherGraphs.length; i++) {
              if (feature.children.length === 0) {
                let tempReference = this.refOtherGraphs[i].current.state;
                tempReference.prevFeatureId = this.state.prevFeatureId;
                tempReference.featureId = this.state.featureId;
                tempReference.map.current.fitBounds(e.target.getBounds());
                tempReference.featureBounds = this.state.featureBounds;
                tempReference.geoJsonRef.current.clearLayers();
                tempReference.geoJsonRef.current.addData(feature);
                break;
              }
              let tempReference = this.refOtherGraphs[i].current.state;
              tempReference.prevFeatureId = this.state.prevFeatureId;
              tempReference.featureId = this.state.featureId;
              tempReference.map.current.fitBounds(e.target.getBounds());
              tempReference.featureBounds = this.state.featureBounds;
              tempReference.geoJsonRef.current.clearLayers();
              tempReference.data = tempData;
              tempReference.geoJsonRef.current.addData(this.state.data);
            }
            this.state.fL = this.state.fL + 1;
            for (let i = 0; i < this.refOtherGraphs.length; i++) {
              // Force update other graphs //
              this.refOtherGraphs[i].current.state.fL = this.state.fL;
              this.refOtherGraphs[i].current.forceUpdate();
            }
            this.forceUpdate();
          }
        },
      });
    }
  }

  render() {
    let features = {
      fromWhere: this.state.fromWhere,
      featureLevel: this.state.featureLevel,
      featureId: this.state.featureId,
    };
    let zoom = 0;
    let dragF = true;
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression 
    containing some mobile devices keywords 
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      zoom = 6;
      dragF = false;
    } else {
      zoom = this.state.zoom;
      dragF = true;
    }

    return (
      <>
        <MapContainer
          id={"map"}
          center={this.state.position}
          scrollWheelZoom={false}
          dragging={dragF}
          zoom={zoom}
          zoomControl={this.zoomControl}
          minZoom={6}
          maxZoom={9}
          style={{
            background: "white",
            height: "35rem",
            maxHeight: "35rem",
            minHeight: "20rem",
            zIndex: 10,
          }}
          ref={this.state.map}
          preferCanvas={true}
        >
          {!this.disableLevelButton ? (
            <LevelButton
              mapPointer={this}
              refOtherGraphs={this.refOtherGraphs}
            />
          ) : (
            <></>
          )}
          {this.enableLegends ? (
            <Legends
              mapPointer={this}
              value1={this.state.legendIndex}
              legend={this.legendsData[this.state.legendIndex]}
            />
          ) : (
            <></>
          )}

          {this.state.map && (
            <GeotiffLayer
              url={this.state.tiffUrl}
              options={this.options}
              features={features}
              highestFeatureId={this.state.highestFeatureId}
            />
          )}
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" />
          {this.state.map && (
            <GeoJSON
              ref={this.state.geoJsonRef}
              data={this.state.data}
              onEachFeature={this.handleEachFeature}
              style={{
                fillColor: "transparent",
                color: "black",
                weight: 0.9,
                zIndex: 10,
              }}
            />
          )}
        </MapContainer>
      </>
    );
  }
}
