import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CropYield from "./CropYield";
import Drought from "./Drought";

const Home = () => {
  //This showing the Nav link on the main home page.
  return (
    <>
      <div className="bg-[#D37D28] my-auto py-5 md:py-7 font-Arialbold">
        <div className="px-3">
          <NavLink
            style={({ isActive }) =>
              isActive
                ? { color: "black", background: "white" }
                : { color: "white" }
            }
            to="/"
            className="rounded-md text-sm md:text-lg p-2  no-underline text-white hover:text-blue-dark ml-2 hover:text-gray-700  focus:text-gray-700 "
          >
            Drought Condition
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive
                ? { color: "black", background: "white" }
                : { color: "white" }
            }
            to="/cropYield"
            className="rounded-md text-sm md:text-lg p-2  no-underline text-white hover:text-blue-dark ml-2 hover:text-gray-700  focus:text-gray-700 "
          >
            Crop Yield
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Home;
