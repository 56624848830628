import L from "leaflet";
import { useEffect } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";

window.proj4 = proj4;

// This component handles different levels of areas in shapefiles.
const LevelButton = ({ refOtherGraphs, mapPointer }) => {
  let reference = mapPointer.state;
  const context = useLeafletContext();
  const map = useMap();
  useEffect(() => {
    // This function will create a button, call it as level-up button.
    // By clicking this button users can view a higher level of administrative unit plotted on the map as compared to the current one.
    // For example, if map is displaying district boundaries then when user clicks this button it will display state boundaries and the district boundaries will be deleted.
    const control = L.Control.extend({
      options: { position: "topleft" },
      onAdd: function () {
        const btn = L.DomUtil.create("button");
        btn.title = "Level Button";
        btn.textContent = "↑";
        btn.className = "customButton";
        // This defines onClick functionality
        btn.onclick = function (e) {
          // check fromWhere //
          if (reference.fromWhere === "interactive") {
            // go to the lowest level //
            mapPointer.setFeatureLevel(mapPointer.state.featureLevel);
            mapPointer.state.map.current.fitBounds(
              mapPointer.state.featureBounds[0]
            );
            mapPointer.forceUpdate();
            reference.onMapUpdate(reference.featureId)
          } else {
            reference.fL - 1 <= 0
              ? (reference.fL = 0)
              : (reference.fL = reference.fL - 1);
            mapPointer.forceUpdate();
            // first clear the current level in shapefile
            reference.featureId =
              reference.prevFeatureId.length > 0
                ? reference.prevFeatureId[reference.prevFeatureId.length - 1]
                : reference.featureId;
            reference.prevFeatureId.pop();
            mapPointer.state.geoJsonRef.current.clearLayers();
            // fetch data for new shapefile
            const tempData = mapPointer.referenceData.features.filter(
              (item) => {
                return item.parent === mapPointer.state.featureId;
              }
            );
            mapPointer.state.data = tempData;
            // add data to geoJson layer
            mapPointer.state.geoJsonRef.current.addData(mapPointer.state.data);
            if (reference.featureBounds.length > 0)
              mapPointer.state.map.current.fitBounds(
                reference.featureBounds[reference.featureBounds.length - 1]
              );
            // Do the same work for all other linked maps
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              let tempReference = mapPointer.refOtherGraphs[i].current.state;
              tempReference.featureId = reference.featureId;
              tempReference.geoJsonRef.current.clearLayers();
              if (reference.featureBounds.length > 0)
                tempReference.map.current.fitBounds(
                  reference.featureBounds[reference.featureBounds.length - 1]
                );
              tempReference.data = tempData;
              tempReference.geoJsonRef.current.addData(mapPointer.state.data);
              tempReference.fL - 1 <= 0
                ? (tempReference.fL = 0)
                : (tempReference.fL = tempReference.fL - 1);
              // mapPointer.refOtherGraphs[i].setFeatureLevel(fL);
            }
            for (let i = 0; i < refOtherGraphs.length; i++) {
              refOtherGraphs[i].current.forceUpdate();
            }
            mapPointer.state.featureBounds.pop();
            return true;
          }
        };
        return btn;
      },
    });
    var levelControl = new control();
    context.map.addControl(levelControl);
    return () => {
      context.map.removeControl(levelControl);
    };
  }, [context, mapPointer, map]);
  return null;
};

export default LevelButton;
