import React from "react";
import { NavLink } from "react-router-dom";
import logo1 from "../static/img/feed-the-future.jpg";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  // Location is use for getting path from URL.
  const location = useLocation();
  // Creating header and make navlinks

  return (
    <>
      <div className="mx-0 w-full">
        <img src={logo1} className="w-1/2 md:w-1/4" />
      </div>

      <nav className="w-full font-Arialbold flex items-center flex-row text-left justify-between py-4 px-5 bg-[#4799B5] shadow">
        <div className="">
          <div className="flex justify-center items-center">
            <NavLink
              to="/"
              className=" font-Gill font-bold my-auto lg:text-2xl md:text-md sm:text-base text-white no-underline text-grey-darkest hover:text-blue-dark"
            >
              SENEGAL DROUGHT AND CROP WATCH
            </NavLink>
          </div>
        </div>
        {/* Mobile Menu Button */}
        <div
          className="lg:hidden md:flex sm:flex xs:flex flex"
          id="mobile-menu-button"
        >
          <button className="outline-none mobile-menu-button">
            <svg
              className="w-6 h-6 text-white"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <div className="hidden justify-end font-Gill font-bold lg:flex md:hidden sm:hidden xs:hidden">
          <NavLink
            style={({ isActive }) =>
              isActive || location.pathname == "/cropYield"
                ? { color: "#4799B5", background: "white" }
                : { color: "white" }
            }
            to="/"
            className="rounded-md lg:text-md md:text-base sm:text-base p-2  no-underline text-white hover:text-blue-dark ml-2 hover:text-gray-700  focus:text-gray-700 "
          >
            Current Maps
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive || location.pathname == "/cropYieldInteractive"
                ? { color: "#4799B5", background: "white" }
                : { color: "white" }
            }
            to="/interactive"
            className="rounded-md lg:text-md md:text-base sm:text-base p-2 no-underline text-white hover:text-blue-dark ml-2 hover:text-gray-700   focus:visited:text-gray-700"
          >
            Interactive Maps
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive
                ? { color: "#4799B5", background: "white" }
                : { color: "white" }
            }
            to="/Reports"
            className="rounded-md lg:text-md md:text-base sm:text-base p-2 no-underline text-white hover:text-blue-dark ml-2 hover:text-gray-700   focus:visited:text-gray-700"
          >
            Creating Reports
          </NavLink>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div className="mobile-menu hidden bg-[#3f89a2]" id="mobile-menu">
        <NavLink
          style={({ isActive }) =>
            isActive || location.pathname == "/cropYield"
              ? { color: "#4799B5", background: "white" }
              : { color: "white" }
          }
          to="/"
          onClick={() => {
            // Click the mobile menu button to close the menu
            $("#mobile-menu-button").click();
          }}
          className="lg:text-md md:text-base sm:text-base p-2  no-underline text-white hover:text-blue-dark m-2 hover:text-gray-700  focus:text-gray-700 "
        >
          Current Maps
        </NavLink>
        <NavLink
          style={({ isActive }) =>
            isActive || location.pathname == "/cropYieldInteractive"
              ? { color: "#4799B5", background: "white" }
              : { color: "white" }
          }
          to="/interactive"
          onClick={() => {
            // Click the mobile menu button to close the menu
            $("#mobile-menu-button").click();
          }}
          className="lg:text-md md:text-base sm:text-base p-2 no-underline text-white hover:text-blue-dark m-2 hover:text-gray-700   focus:visited:text-gray-700"
        >
          Interactive Maps
        </NavLink>
        <NavLink
          style={({ isActive }) =>
            isActive
              ? { color: "#4799B5", background: "white" }
              : { color: "white" }
          }
          to="/Reports"
          onClick={() => {
            // Click the mobile menu button to close the menu
            $("#mobile-menu-button").click();
          }}
          className="lg:text-md md:text-base sm:text-base p-2 no-underline text-white hover:text-blue-dark m-2 hover:text-gray-700   focus:visited:text-gray-700"
        >
          Creating Reports
        </NavLink>
      </div>
    </>
  );
};
// on document ready
$(function () {
  // Mobile Menu Button
  $("#mobile-menu-button").on("click", function () {
    if ($(".mobile-menu").hasClass("hidden")) {
      $(".mobile-menu").removeClass("hidden");
      $(".mobile-menu").addClass("flex");
      $(".mobile-menu").addClass("flex-col");
    } else {
      $(".mobile-menu").removeClass("flex");
      $(".mobile-menu").removeClass("flex-col");
      $(".mobile-menu").addClass("hidden");
    }
  });
});
export default Navbar;
