import { useEffect } from "react";
import L from "leaflet";
import "../static/css/map.css";

const Legends = ({ mapPointer, value1, legend }) => {
  // Creating legends corresponding to each variable name
  useEffect(() => {
    if (!mapPointer.state.map.current) return;

    const legend_widget = L.control({ position: "bottomright" });

    let rows = [];
    // Adding legends using inbuild function called map.
    legend_widget.onAdd = () => {
      const div = L.DomUtil.create("div", "legend");
      legend.legend_colors.map((color, index) => {
        return rows.push(`
                <div class="row text-xs">
                <i style="background: ${color}"></i>${legend.legend_names[index]}
              </div>
              `);
      });
      div.innerHTML = rows.join("");
      return div;
    };
    legend_widget.addTo(mapPointer.state.map.current);
    //this remove the previous legend when new legend show
    return () => {
      legend_widget.remove();
    };
  }, [mapPointer, value1]);
};

export default Legends;
