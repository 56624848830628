import React, { useState, useRef, useEffect } from "react";
import "../static/css/map.css";
import { DownloadFile, HTMLtoPNG } from "./DownloadFile";
import MapTiff from "./MapTiff.js";
import { pathGenerator, dateToString } from "./Utils";
import Home from "./Home.js";
import processedShapefiles from "../shapefiles/processedShapefiles.json";
import { getTiff, getLastMapUpdateDate } from "../FetchReq";
import myCache, { cacheConfig } from "../Cache.js";

let getMapUpdateDate = async () => {
  const date_from_server = await getLastMapUpdateDate().then((res) => {
    let date = res["lastMapUpdateDate"];
    return date;
  });

  let dateObj = new Date(date_from_server);
  return dateObj;
};

const Drought = () => {
  let [generatedDate, setGeneratedDate] = useState(null);
  // for display on the webpage
  let [date, setDate] = useState(null);

  useEffect(() => {
    getMapUpdateDate().then((dateObj) => {
      setGeneratedDate(dateObj);
      let lastMapUpdateDate = new Date(generatedDate);
      setDate(
        `${lastMapUpdateDate.toLocaleString("default", { month: "long" })} ${
          (lastMapUpdateDate.getDate() < 10 ? "0" : "") +
          lastMapUpdateDate.getDate()
        }, ${lastMapUpdateDate.getFullYear()}`
      );
    });
  }, [date]);

  // This function return the date according to the radio button selected by the end User for both nowcast and forecast for selecting specific time.
  function dateChange(nae, sign) {
    //The parameter “sign” determines whether the shift in date will be forward or backward. Forward for forecast and back for nowcast.
    let key = nae[0];
    let shift = 0;
    if (nae === "Next Week") nae = "1W";
    if (nae !== "Current") {
      shift = parseInt(nae[0]);
      key = nae[1];
    }
    shift = shift * sign;

    let DateObj = new Date(generatedDate);

    let handles = { W: [0, 0, 1], M: [0, 1, 0], Y: [1, 0, 0], C: [0, 0, 0] };
    DateObj.setDate(DateObj.getDate() - handles[key][2] * shift * 7);
    DateObj.setMonth(DateObj.getMonth() - handles[key][1] * shift);
    DateObj.setFullYear(DateObj.getFullYear() - handles[key][0] * shift);
    let currentDate = dateToString(DateObj);
    return currentDate;
  }

  function handleDateChangeNowcast(e) {
    // handle date change and tiffUrl for nowcast map
    const nae = e.target.value;
    const newDate = dateChange(nae, 1);
    setDates(newDate);
    tiffUrlNowcast = pathGenerator({
      index: legendIndex,
      prediction: "nowcast",
      date: newDate,
    });
    mapNowcast.current.setTiffUrl(tiffUrlNowcast);
    // getTiff(tiffUrlNowcast, "nowcast");
  }

  function handleDateChangeForecast(e) {
    // handle date change and tiffUrl for forecast map
    const nae = e.target.value;
    const newDate = dateChange(nae, -1);
    setNdates(newDate);
    tiffUrlForecast = pathGenerator({
      index: legendIndex,
      prediction: "forecast",
      date: newDate,
    });
    mapForecast.current.setTiffUrl(tiffUrlForecast);
    // getTiff(tiffUrlForecast, "forecast");
    // mapForecast.current.setFeatureLevel(0);
  }

  const mapNowcast = useRef(); // reference variable for nowcast map
  const mapForecast = useRef(); // reference variable for forecast map

  // THIS IS RESPONSIBLE FOR GENERATING THE URLS TO REQUEST FOR SPI3 TIFF DATA //
  //////
  // const legendsData = legendsInfo["legendsInfo"]; // data for each variable
  const legendsData = myCache.get("legendsData")["legendsInfo"];
  var legendIndex = 5; // it's the index of variable name = spi3
  const [dates, setDates] = useState(dateChange("Current", 1)); // date variable for nowcast map
  const [ndates, setNdates] = useState(dateChange("Next Week", -1)); // date variable for forecast map
  //////

  var tiffUrlNowcast = pathGenerator({
    index: legendIndex,
    prediction: "nowcast",
    date: dates,
  });
  var tiffUrlForecast = pathGenerator({
    index: legendIndex,
    prediction: "forecast",
    date: ndates,
  });

  // Click the radio button whose name is time and value is current
  useEffect(() => {
    document.getElementsByName("time")[7].click();
    document.getElementsByName("timeFor")[0].click();
  }, [date]);

  return (
    <>
      <Home />
      <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 sm:grid-cols:1 content-start text-3xl px-5 justify-start items-start font-ArialNormal">
        {/* Left side  */}
        <div className="left md:mb-10 md:mx-5 mx-0 mt-5">
          <span>
            Drought Monitor <br />
            <h3 className="text-xl"> Map Update: {date} </h3>
          </span>
          <div className="flex">
            <div className="mt-4">
              {/* Create Radio Buttons  */}
              <div className="mt-2 text-lg flex flex-row flex-wrap">
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="One year ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="1Y"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">1Y</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="Six months ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="6M"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">6M</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="Three months ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="3M"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">3M</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="One month ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="1M"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">1M</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="Three weeks ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="3W"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">3W</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="Two weeks ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="2W"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">2W</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="One week ago"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="1W"
                    onClick={handleDateChangeNowcast}
                  />
                  <span className="p-1 md:text-base ">1W</span>
                </label>
                <label className="inline-flex items-center lg:pr-5 pr-3">
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="time"
                    value="Current"
                    onClick={handleDateChangeNowcast}
                    defaultChecked
                  />
                  <span className="p-1 md:text-base">Current</span>
                </label>
              </div>
            </div>
          </div>
          {/* Display map from map component  */}
          <div className="mt-4 px-1" id="mapss">
            {/* <Map val={true} /> */}
            {
              <MapTiff
                legendsData={legendsData}
                predictionType={"nowcast"}
                enableLegends={true}
                legendIndex={legendIndex}
                ref={mapNowcast}
                data={processedShapefiles}
                tiffUrl={tiffUrlNowcast}
                refOtherGraphs={[mapForecast]}
                fromWhere={"forecast"}
              />
            }

            <hr className="w-full my-5" />
            <div className="flex justify-end">
              <h1 className="lg:text-2xl text-base items-start">
                Map Download
              </h1>
            </div>
            <div className="flex justify-end">
              <div className="my-5">
                {
                  <DownloadFile
                    tiffUrl={tiffUrlNowcast}
                    fileName={legendsData[legendIndex].variableCode}
                    innerRef={mapNowcast}
                    prediction={"nowcast"}
                    date={dates}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {/* Right side  */}
        <div className="right mt-5">
          <span>
            Drought Forecast <br />
            <h3 className="text-xl"> Map Update: {date} </h3>
          </span>
          <div className="flex ">
            <div className="mt-4">
              <div className="mt-2 text-lg flex flex-row flex-wrap">
                {/* Radio Buttons  */}
                <label className="inline-flex items-center lg:pr-5 pr-3">
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="Next Week"
                    onClick={handleDateChangeForecast}
                    defaultChecked
                  />
                  <span className="p-1 md:text-base ">Next Week</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="For next two weeks"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="2W"
                    onClick={handleDateChangeForecast}
                  />
                  <span className="p-1 md:text-base ">2W</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="For next three weeks"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="3W"
                    onClick={handleDateChangeForecast}
                  />
                  <span className="p-1 md:text-base ">3W</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="For next one Month"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="1M"
                    onClick={handleDateChangeForecast}
                  />
                  <span className="p-1 md:text-base ">1M</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="For next two Months"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="2M"
                    onClick={handleDateChangeForecast}
                  />
                  <span className="p-1 md:text-base ">2M</span>
                </label>
                <label
                  className="inline-flex items-center lg:pr-5 pr-3"
                  title="For next three Months"
                >
                  <input
                    type="radio"
                    className="form-radio lg:h-6 lg:w-6 w-4 h-4"
                    name="timeFor"
                    value="3M"
                    onClick={handleDateChangeForecast}
                  />
                  <span className="p-1 md:text-base ">3M</span>
                </label>
              </div>
            </div>
          </div>
          {/* Display map from map component  */}
          <div className="mt-4 px-1 " id="mapss">
            {/* <Map val={false} /> */}
            {
              <MapTiff
                legendsData={legendsData}
                predictionType={"forecast"}
                enableLegends={true}
                legendIndex={legendIndex}
                ref={mapForecast}
                data={processedShapefiles}
                tiffUrl={tiffUrlForecast}
                refOtherGraphs={[mapNowcast]}
                fromWhere={"nowcast"}
              />
            }
            <hr className="w-full my-5" />
            <h1 className="lg:text-2xl text-base flex justify-end">
              Map Download
            </h1>
            <div className="flex justify-end">
              <div className="my-5">
                {
                  <DownloadFile
                    tiffUrl={tiffUrlForecast}
                    fileName={legendsData[legendIndex].variableCode}
                    innerRef={mapForecast}
                    prediction={"forecast"}
                    date={ndates}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drought;
