import React from "react";
import { Link } from "react-router-dom";
import Home from "./Home.js";

const CropYield = () => {
  return (
    <>
      {/* CropYield page for future use  */}
      <Home />
      <div className="text-center">
        <h1 className="text-xl md:text-4xl my-10">Available soon</h1>
      </div>
    </>
  );
};

export default CropYield;
