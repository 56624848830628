import myCache, { cacheConfig } from "./Cache.js";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import InteractiveMap from "./components/InteractiveMap";
import Reports from "./components/Reports";
import Spatial from "./components/Spatial";
import Footer from "./components/Footer";
import CropYield from "./components/CropYield";
import Drought from "./components/Drought";
import CropYieldInteractive from "./components/CropYieldInteractive";
import Modal from "./components/Modal";
import React, { Component, useEffect } from "react";
import { getLegendsData } from "./FetchReq";
const server_config = require("./config/server.config.json");

function App() {
  // Fetching Legends Data One Time //
  const [legendsData, setLegendsData] = React.useState(null);
  useEffect(() => {
    const endpoint = "//" + server_config["SERVER_URL"] + "/webhooks/legendsInfo";
    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLegendsData(data);
          // console.log(data)
        });
      }
    });
  }, []);

  // console.log(legendsData);
  myCache.set("legendsData", legendsData);
  
  return (
    <>
      {/* To navigat between pages  */}
      {legendsData !== null ? (
        <>
          <Navbar />
          <Modal />
          <Routes>
            <Route path="/" element={<Drought />} />
            <Route path="/cropYield" element={<CropYield />} />
            <Route
              path="/cropYieldInteractive"
              element={<CropYieldInteractive />}
            />
            <Route path="/interactive" element={<InteractiveMap />} />
            <Route path="/Reports" element={<Reports />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <>
          <Navbar />
          <Modal />
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
