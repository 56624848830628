import NodeCache from "node-cache";
const myCache = new NodeCache({ stdTTL: 0 });
export const cacheConfig = require("./config/cache.config.json");

// Clear Cache  //
// myCache.flushAll();

// setInterval(() => {
//     myCache.flushAll();
// }, 1000 * 60 * 5);

export default myCache;
