import React from "react";
import Home from "./Home.js";

const Modal = () => {
  return (
    <>
      <div id="modal" className="z-50 w-full fixed bottom-0 hidden justify-end">
        <div className="flex z-50 bg-white border border-solid border-red-500 m-1 items-end lg:text-xl text-base p-3">
          <div slot="avatar" className="my-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-alert-octagon w-5 h-5 mx-2"
            >
              <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
          </div>
          <div
            id="modal_text"
            className="max-w-full flex-initial my-auto"
          >
            There is an error in your code
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
