/*
## File Description ##
- This file is the entry point for fetching the tiff files from the server. 
- It uses the WebSocketHandler.js file to create a websocket connection to the server per request
  and fetch the tiff files.
*/

import myCache, { cacheConfig } from "./Cache.js";
import { displayError } from "./components/Utils.js";
const server_config = require("./config/server.config.json");
import { createWSHandlerInstance } from "./WebSocketHandler.js";

let listOfInstances = [];

// Destroy Inactive Websocket Connections //
setInterval(() => {
  listOfInstances.forEach((instance) => {
    if (instance.isInactive()) {
      listOfInstances = listOfInstances.filter((i) => i !== instance);
    }
  });
}, 10000);

// This function is used to get the lower level tiff files //
async function getTiff(tiffUrl, highestFeatureId) {
  const URL_split = tiffUrl.split("/");
  const prediction = URL_split[0];
  const subdirectory = URL_split[1];
  const filename = URL_split[2];

  let params_1 = {
    prediction: prediction,
    subDirectory: subdirectory,
    fileName: filename,
    highestFeatureID: highestFeatureId,
  };
  if (myCache.has(filename + "_" + params_1.highestFeatureID)) {
    return;
  }

  const endpoint = "ws://" + server_config["SERVER_URL"] + "/geoTiffWS/";

  const WSH = createWSHandlerInstance(endpoint);
  listOfInstances.push(WSH);
  WSH.send(params_1);
}

// This function is used to get the lower level tiff files //
async function getTiff_HTTPReq(tiffUrl, highestFeatureId, featureId, level) {
  const URL_split = tiffUrl.split("/");
  const prediction = URL_split[0];
  const subdirectory = URL_split[1];
  const filename = URL_split[2];

  let params_1 = {
    prediction: prediction,
    subDirectory: subdirectory,
    fileName: filename,
    highestFeatureID: highestFeatureId,
  };
  if (myCache.has(filename + "_" + featureId)) {
    return;
  }

  // Fetch //

  const params = {
    prediction: params_1.prediction,
    subDirectory: params_1.subDirectory,
    fileName: params_1.fileName,
    highestFeatureID: params_1.highestFeatureID,
    featureID: featureId,
    featureLevel: level,
  };
  if (myCache.has(params.fileName + "_" + featureId)) {
    return;
  }
  fetch(
    "//" +
      server_config["SERVER_URL"] +
      "/geoTiff/" +
      params.prediction +
      "/" +
      params.subDirectory +
      "/" +
      params.fileName +
      "/" +
      params.highestFeatureID +
      "/" +
      params.featureLevel +
      "/" +
      params.featureID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        response.arrayBuffer().then((data) => {
          // convert arrayBuffer to base64 string
          data = btoa(
            new Uint8Array(data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          myCache.set(params.fileName + "_" + params.featureID, data);
        });
      }
      else{
        displayError("Error: Data Not Found");
      }
    })
    .catch((error) => {
      // console.log(error);
      displayError("Error: Data Not Found");
    });
}

async function getTSData(params) {
  // console.log(params["selectedDate"])
  const endpoint =
    "//" +
    server_config["SERVER_URL"] +
    "/TSData/" +
    params["periodicity"] +
    "/" +
    params["selectedDate"] +
    "/" +
    params["prediction"] +
    "/" +
    params["variableCode"] +
    "/" +
    params["featureId"] +
    "/" +
    params["highestFeatureId"] +
    "/" +
    params["featureLevel"];

  const dataToPlot = fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => (response.ok ? response.json() : []))
    .catch((error) => {
      // console.log(error);
      return [];
    });
  return dataToPlot;
}

async function getLastMapUpdateDate() {
  const endpoint =
    "//" + server_config["SERVER_URL"] + "/webhooks/lastMapUpdateDate";

  const lastMapUpdateDate = fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => (response.ok ? response.json() : []))
    .catch((error) => {
      // console.log(error);
      return [];
    });
  return lastMapUpdateDate;
}

export {
  getTiff,
  getTSData,
  getLastMapUpdateDate,
  getTiff_HTTPReq
};
